import React from 'react'

import { navigate } from 'gatsby'
import { isLoggedIn } from '../services/auth'
import NotFoundPage from '../pages/404'

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  // if (!isLoggedIn() && location.pathname !== `/app/`) {
  //   navigate('/app/')
  //   return null
  // }

  if (!isLoggedIn() && location.pathname !== `/app/`) {
    navigate('/app/')
    console.log(isLoggedIn(), location.pathname)
    return <NotFoundPage {...rest} />
    // return null
  }
  return <Component {...rest} />
}

export default PrivateRoute
